import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import SEO from '../components/seo';
import Services from "../components/Services";


// TODO Ajouter un "main-not-display" pour les résolutions non prises en charges

const ServicesTemplate = ({ data }) => {

    // const [datas] = React.useState(data.wpcontent.page);

    return (
        <Layout>
            <SEO lang="fr_FR" title='Services'/>
            <Services />
        </Layout>
    );
};

export default ServicesTemplate;

// export const query = graphql`
//     query Page($id: ID!) {
//         wpcontent {
//             page(id: $id) {
//                 content
//                 title
//                 uri
//             }
//         }
//     }
// `;